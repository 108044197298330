<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">
                  {{ $t('MENU.SALARY.PAY') }}
                </h3>
              </div>
              <div class="card-button">
                <v-btn to="/payment_statements/create" color="primary" small>
                  {{ $t('MENU.SALARY.PAY') }}+
                </v-btn>
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="list"
                no-data-text="Malumot kiritilmagan"
                :loading="isLoading"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:[`item.index`]="{ item }">
                  {{
                    list
                      .map(function (x) {
                        return x.id
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div style="text-align: right !important">
                    <action :index="item"></action>
                  </div>
                </template>
                <template v-slot:[`item.payed_status_name`]="{ item }">
                  <span
                    class="payed_status"
                    :class="'bg-' + item.payed_status_color"
                    >{{ item.payed_status_name }}</span
                  >
                </template>
              </v-data-table>
            </div>
            <div class="text-left my-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="10"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getCount.count / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import action from './Action'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  components: { action },
  data() {
    return {
      perPage: 10,
      currentPage: 1
    }
  },
  created() {
    // this.$store.dispatch("kontragentContractAktSverka");
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  methods: {
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,

          query: { page: value }
        })
      }
      this.$store.dispatch('getPaymentStatements', value)
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: this.$t('TABLE_HEADER.DATE'),
          value: 'oper_date'
        },
        {
          text: this.$t('TABLE_HEADER.AMOUNT'),
          value: 'total_elements.total'
        },
        {
          text: this.$t('TABLE_HEADER.PAYMENT_STATUS'),
          value: 'payed_status_name'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    list() {
      return this.$store.state.requests.paymentStatements.results
    },
    getCount() {
      return this.$store.state.requests.paymentStatements
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.SALARY.TITLE') },
      { title: this.$t('MENU.SALARY.PAY') }
    ])
  }
}
</script>

<style scoped>
.payed_status {
  color: #fff;

  padding: 5px;
  border-radius: 4px;
}
</style>
